






























































@import '../styles/variables.styl';

.contentbox {
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 549px) {
    .boxContent {
      max-height: 0;
      overflow: hidden;
      transition: 0.5s ease max-height;
      transition-delay: 0.2s;

      .kurztext {
        opacity: 0;
        transition: 0.5s ease opacity;
        transition-delay: 0.4s;
      }

      .blocklink {
        opacity: 0;
        transition: 0.5s ease opacity;
        transition-delay: 0.6s;
      }
    }

    &.open .boxContent {
      max-height: 20rem;

      .kurztext, .blocklink {
        opacity: 1;
      }
    }
  }

  .boxContent {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .greyBox {
    min-height: 4rem;
    background-color: medinGrey;
    padding: 1rem;
    margin-bottom: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }

    .titel, .kurztext {
      flex: 1;
    }

    .kurztext {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    .boxNavBar {
      display: grid;
      grid-template-columns: auto 1.5rem;
      grid-template-rows: 1fr;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  .blocklink {
    height: 2rem;
    width: fit-content;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1rem;
    padding-right: 2rem;
    margin-bottom: 1.5rem;
  }
}
