













@import '../styles/variables.styl';

a.cta {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  width: 12rem;
  height: 3.75rem;
  padding-top: 0.3rem;
  margin-bottom: 1rem;

  &.rounded {
    border-radius: 2rem;
  }

  &.red {
    background-color: red;
  }

  &.white {
    background-color: medinWhite;
    color: medinBlue;
  }

  h2 {
    margin-bottom: 0;
    line-height: 1;
  }
}
