



































































@import '../styles/variables.styl';

#mainHead {
  padding: 1rem;
  min-height: 3.4rem;
  background-color: medinBlue;
  color: white;
}

a {
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
  }
}

.innerHead {
  margin: 1rem;
}

.sublogo {
  line-height: 1.2;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 1rem;
}

.hamberder {
  margin-top: 1rem;

  &:hover, &:focus {
    opacity: 1;
  }
}

.topline {
  display: flex;
  justify-content: space-between;
}

.subnav {
  margin-bottom: 3rem;

  div {
    margin-bottom: 1rem;
  }
}

.drawer {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease all;
}

.is-open .drawer {
  margin-top: 0.5rem;
  max-height: 70rem;
  max-height: fit-content;
  overflow: visible;
}
