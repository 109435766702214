@import url(//hello.myfonts.net/count/39f557);
.accordeon[data-v-402f61e4] {
  margin-bottom: 1rem;
}
.accordeon .accordeonTitle[data-v-402f61e4]:hover {
  color: #0a41c8;
  cursor: pointer;
}
.accordeon .accordeonTitle[data-v-402f61e4] {
  display: flex;
  align-items: top;
}
.accordeon .arrowContainer[data-v-402f61e4] {
  margin-top: 0.5rem;
}
.accordeon .arrow[data-v-402f61e4] {
  height: 0.8rem;
  margin-bottom: 0.3rem;
  margin-right: 0.3rem;
}
.accordeon .arrow[data-v-402f61e4]:hover {
  color: #0a41c8;
}
@media screen and (min-width: 1920px) {
.accordeon .arrowContainer[data-v-402f61e4] {
    margin-top: 0.75rem;
}
.accordeon .arrow[data-v-402f61e4] {
    height: 1.375rem;
    margin-right: 1rem;
}
}
.accordeon h3[data-v-402f61e4] {
  display: inline;
}
.accordeon div.accordeonContent[data-v-402f61e4] {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease max-height;
  transition-delay: 0.2s;
}
@media screen and (min-width: 768px) {
.accordeon div.accordeonContent p[data-v-402f61e4] {
    width: 75%;
}
}
@media screen and (min-width: 1920px) {
.accordeon div.accordeonContent p[data-v-402f61e4] {
    margin-bottom: 1.6rem;
    width: 50%;
}
}
.accordeon div.accordeonContent ul[data-v-402f61e4] {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 1.6rem;
}
.accordeon div.accordeonContent ul li[data-v-402f61e4] {
  line-height: 1.4;
  padding-left: 0.2rem;
}
@media screen and (min-width: 768px) {
.accordeon div.accordeonContent ul[data-v-402f61e4] {
    width: 75%;
}
}
@media screen and (min-width: 1920px) {
.accordeon div.accordeonContent ul[data-v-402f61e4] {
    width: 50%;
    padding-left: 2.375rem;
}
.accordeon div.accordeonContent ul li[data-v-402f61e4] {
    padding-left: 1.2rem;
}
}
.accordeon.isOpen div.accordeonContent[data-v-402f61e4] {
  max-height: 50rem;
}
.accordeon a[data-v-402f61e4] {
  color: #000;
  text-decoration: underline;
}
.accordeon a[data-v-402f61e4]:hover {
  color: #000;
  text-decoration: underline;
}
/*# sourceMappingURL=src/components/Accordeon.css.map */
.arrow[data-v-c1447850] {
  transition: all 0.3s ease;
}
.down[data-v-c1447850] {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.up[data-v-c1447850] {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.left[data-v-c1447850] {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.right[data-v-c1447850] {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
/*# sourceMappingURL=src/components/Arrow.css.map */
button[data-v-5812ce9f] {
  background-color: #0a41c8;
  border-radius: 1rem;
  padding-left: 1rem;
}
/*# sourceMappingURL=src/components/BackButton.css.map */
.contentbox[data-v-994bbc74] {
  display: flex;
  flex-direction: column;
}
.contentbox[data-v-994bbc74]:hover {
  cursor: pointer;
}
@media screen and (max-width: 549px) {
.contentbox .boxContent[data-v-994bbc74] {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease max-height;
    transition-delay: 0.2s;
}
.contentbox .boxContent .kurztext[data-v-994bbc74] {
    opacity: 0;
    transition: 0.5s ease opacity;
    transition-delay: 0.4s;
}
.contentbox .boxContent .blocklink[data-v-994bbc74] {
    opacity: 0;
    transition: 0.5s ease opacity;
    transition-delay: 0.6s;
}
.contentbox.open .boxContent[data-v-994bbc74] {
    max-height: 20rem;
}
.contentbox.open .boxContent .kurztext[data-v-994bbc74],
  .contentbox.open .boxContent .blocklink[data-v-994bbc74] {
    opacity: 1;
}
}
.contentbox .boxContent[data-v-994bbc74] {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.contentbox .greyBox[data-v-994bbc74] {
  min-height: 4rem;
  background-color: #efefef;
  padding: 1rem;
  margin-bottom: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
.contentbox .greyBox[data-v-994bbc74] {
    margin-bottom: 0;
}
}
.contentbox .greyBox .titel[data-v-994bbc74],
.contentbox .greyBox .kurztext[data-v-994bbc74] {
  flex: 1;
}
.contentbox .greyBox .kurztext[data-v-994bbc74] {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.contentbox .greyBox .boxNavBar[data-v-994bbc74] {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1.5rem;
  grid-template-columns: auto 1.5rem;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
}
.contentbox .greyBox .boxNavBar > *[data-v-994bbc74]:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.contentbox .greyBox .boxNavBar > *[data-v-994bbc74]:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.contentbox h3[data-v-994bbc74] {
  margin-bottom: 0;
}
.contentbox .blocklink[data-v-994bbc74] {
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 1rem;
  padding-right: 2rem;
  margin-bottom: 1.5rem;
}
/*# sourceMappingURL=src/components/ContentBox.css.map */
@media screen and (min-width: 550px) {
.contentboxes ul[data-v-5996b314] {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1rem 1fr;
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-gap: 1rem;
}
.contentboxes ul > *[data-v-5996b314]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.contentboxes ul > *[data-v-5996b314]:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
}
@media screen and (min-width: 1100px) {
.contentboxes ul[data-v-5996b314] {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1rem 1fr 1rem 1fr;
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
}
.contentboxes ul > *[data-v-5996b314]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.contentboxes ul > *[data-v-5996b314]:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
.contentboxes ul > *[data-v-5996b314]:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
}
}
/*# sourceMappingURL=src/components/ContentBoxes.css.map */
a.cta[data-v-00baf379] {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  width: 12rem;
  height: 3.75rem;
  padding-top: 0.3rem;
  margin-bottom: 1rem;
}
a.cta.rounded[data-v-00baf379] {
  border-radius: 2rem;
}
a.cta.red[data-v-00baf379] {
  background-color: #f00;
}
a.cta.white[data-v-00baf379] {
  background-color: #fff;
  color: #0a41c8;
}
a.cta h2[data-v-00baf379] {
  margin-bottom: 0;
  line-height: 1;
}
/*# sourceMappingURL=src/components/Cta.css.map */

h1[data-v-779a9c76],
h2[data-v-779a9c76] {
  font-weight: normal;
}

.langfilter[data-v-6078e3ef] {
  text-align: right;
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 550px) {
.langfilter[data-v-6078e3ef] {
    text-align: left;
}
}
.langfilter p[data-v-6078e3ef] {
  color: #72ea8b;
  font-size: 100%;
  display: inline;
}
.langfilter button[data-v-6078e3ef] {
  border-radius: 1rem;
  min-width: 3rem;
  min-height: 1.5rem;
  border: none;
  background-color: #c9e9ca;
  color: #72ea8b;
}
.langfilter button.active[data-v-6078e3ef] {
  background-color: #72ea8b;
  color: #c9e9ca;
}
.langfilter button[data-v-6078e3ef]:hover {
  border: 1px solid #72ea8b;
}
ul[data-v-6078e3ef] {
  text-align: right;
  display: inline;
}
ul button[data-v-6078e3ef] {
  color: #c9e9ca;
  transition: color 0.3s ease-in-out;
}
li[data-v-6078e3ef] {
  display: inline;
  margin-left: 0.5rem;
}
@media screen and (min-width: 550px) {
li[data-v-6078e3ef] {
    margin-right: 0.5rem;
    margin-left: 0;
}
}
li.active button[data-v-6078e3ef] {
  color: #72ea8b;
}
li button[data-v-6078e3ef]:hover {
  color: #72ea8b;
}
/*# sourceMappingURL=src/components/LangFilter.css.map */
ul[data-v-7dee4bc2] {
  text-align: right;
}
li[data-v-7dee4bc2] {
  display: inline;
  margin-right: 1rem;
  box-sizing: border-box;
}
li.active a[data-v-7dee4bc2] {
  background-color: #72ea8b;
  color: #0a41c8;
}
li a[data-v-7dee4bc2] {
  text-transform: uppercase;
  font-size: 100%;
  display: inline;
  border-radius: 1rem;
  min-width: 3rem;
  min-height: 1.5rem;
  border: none;
  background-color: #c9e9ca;
  color: #0a41c8;
  padding: 0.095rem 0.83rem;
}
/*# sourceMappingURL=src/components/LangSwitcher.css.map */
#LogoMedin[data-v-286023ac] {
  height: 2.5rem;
}
/*# sourceMappingURL=src/components/Logo.css.map */
.arrow[data-v-c07afbe6] {
  transition: all 0.3s ease;
}
.down[data-v-c07afbe6] {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.up[data-v-c07afbe6] {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.left[data-v-c07afbe6] {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.right[data-v-c07afbe6] {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
/*# sourceMappingURL=src/components/LongArrow.css.map */
#mainHead {
  padding: 1rem;
  min-height: 4rem;
  background-color: medinblue;
  color: #fff;
}
a {
  text-decoration: none;
  color: #fff;
}
a:hover {
  color: #fff;
}
.innerHead {
  margin: 1rem;
}
.sublogo {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: none;
}
.hamberder {
  float: right;
  margin-top: 1rem;
}
.hamberder:hover,
.hamberder:focus {
  opacity: 1;
}
.drawer {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease all;
}
.is-open .drawer {
  margin-top: 2rem;
  max-height: 50rem;
  overflow: visible;
}
/*# sourceMappingURL=src/components/MainHead.css.map */
header#mainHead[data-v-f49027b8] {
  position: fixed;
  top: 0;
  z-index: 15;
  width: 100%;
  padding: 0;
  background-color: #0a41c8;
  height: 5rem;
  transition: height ease 0.3s;
}
@media screen and (min-width: 768px) {
header#mainHead[data-v-f49027b8] {
    height: 4.3rem;
}
}
@media screen and (min-width: 1920px) {
header#mainHead[data-v-f49027b8] {
    height: 5rem;
}
}
header#mainHead.active[data-v-f49027b8] {
  height: 25rem;
}
header#mainHead.active .sublogo[data-v-f49027b8] {
  opacity: 1;
}
.innerHeader[data-v-f49027b8] {
  max-width: 1640px;
  margin: auto;
  height: 3rem;
  padding: 0.5rem 1rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20% auto;
  grid-template-columns: 20% auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.innerHeader > *[data-v-f49027b8]:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.innerHeader > *[data-v-f49027b8]:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
@media screen and (min-width: 1600px) {
.innerHeader[data-v-f49027b8] {
    padding: 1rem 1rem 0;
}
}
.sublogo[data-v-f49027b8] {
  opacity: 0;
  margin-top: 1.5rem;
  line-height: 1.4;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
.sublogo[data-v-f49027b8] {
    margin-top: 1rem;
}
}
.red header#mainHead[data-v-f49027b8]:hover {
  background-color: #db3021;
}
/*# sourceMappingURL=src/components/MainHeadLaptop.css.map */
#mainHead[data-v-dbb5ef84] {
  padding: 1rem;
  min-height: 3.4rem;
  background-color: #0a41c8;
  color: #fff;
}
a[data-v-dbb5ef84] {
  text-decoration: none;
  color: #fff;
}
a[data-v-dbb5ef84]:hover {
  color: #fff;
}
.innerHead[data-v-dbb5ef84] {
  margin: 1rem;
}
.sublogo[data-v-dbb5ef84] {
  line-height: 1.2;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 1rem;
}
.hamberder[data-v-dbb5ef84] {
  margin-top: 1rem;
}
.hamberder[data-v-dbb5ef84]:hover,
.hamberder[data-v-dbb5ef84]:focus {
  opacity: 1;
}
.topline[data-v-dbb5ef84] {
  display: flex;
  justify-content: space-between;
}
.subnav[data-v-dbb5ef84] {
  margin-bottom: 3rem;
}
.subnav div[data-v-dbb5ef84] {
  margin-bottom: 1rem;
}
.drawer[data-v-dbb5ef84] {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease all;
}
.is-open .drawer[data-v-dbb5ef84] {
  margin-top: 0.5rem;
  max-height: 70rem;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  overflow: visible;
}
/*# sourceMappingURL=src/components/MainHeadMobile.css.map */
nav[data-v-382d5df6] {
  margin-top: 1.45rem;
  position: relative;
}
@media screen and (min-width: 1920px) {
nav[data-v-382d5df6] {
    margin-top: 1rem;
}
nav li[data-v-382d5df6]:not(:last-child) {
    padding-top: 0.15rem;
}
}
.parents[data-v-382d5df6] {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4] 9rem;
  grid-template-columns: repeat(4, 1fr) 9rem;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.parents > *[data-v-382d5df6]:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.parents > *[data-v-382d5df6]:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.parents > *[data-v-382d5df6]:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.parents > *[data-v-382d5df6]:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}
.parents > *[data-v-382d5df6]:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.parents > li[data-v-382d5df6] {
  cursor: default;
}
.parents > li h2[data-v-382d5df6] {
  margin-left: 0.75rem;
}
.parents > li:hover .children[data-v-382d5df6] {
  max-height: 100vh;
  opacity: 1;
  padding-bottom: 0.75rem;
}
@media screen and (min-width: 1600px) {
.parents > li:hover .children[data-v-382d5df6] {
    padding-top: 0.5rem;
}
}
.parents > li:hover .children > li[data-v-382d5df6] {
  display: list-item;
}
.parents .mainKnot[data-v-382d5df6] {
  margin-bottom: 1rem;
}
@media screen and (min-width: 1600px) {
.parents .mainKnot[data-v-382d5df6] {
    margin-bottom: 0.5rem;
}
}
.children[data-v-382d5df6] {
  opacity: 0;
  padding: 0 0.75rem;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
  background-color: #0a41c8;
}
.children > li[data-v-382d5df6] {
  display: none;
}
.children h3[data-v-382d5df6] {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4;
  text-transform: none;
  margin-bottom: 0;
}
.red .children[data-v-382d5df6] {
  background-color: #db3021;
}
.red .notfallLink[data-v-382d5df6] {
  display: none;
}
.cta[data-v-382d5df6] {
  position: absolute;
  right: 10%;
  margin-top: 80vh;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.cta.removed[data-v-382d5df6] {
  opacity: 0;
  pointer-events: none;
}
.subnav div[data-v-382d5df6] {
  display: inline-block;
  margin-top: -0.2rem;
}
@media screen and (min-width: 1350px) {
.subnav div[data-v-382d5df6] {
    margin-top: -0.1rem;
}
}
@media screen and (min-width: 1920px) {
.subnav div[data-v-382d5df6] {
    margin-top: 0;
}
}
.subnav div.search[data-v-382d5df6] {
  margin-left: 1rem;
}
/*# sourceMappingURL=src/components/MainNavDesktop.css.map */
nav[data-v-28b205e8] {
  margin-top: 2rem;
}
h2[data-v-28b205e8] {
  margin-bottom: 0;
}
.children[data-v-28b205e8] {
  margin-bottom: 1rem;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out all;
}
.children.is-open[data-v-28b205e8] {
  max-height: 30rem;
}
.children h3[data-v-28b205e8] {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 0.5rem;
  text-transform: none;
  line-height: 1.2;
}
/*# sourceMappingURL=src/components/MainNavMobile.css.map */
.notice[data-v-199a2919] {
  padding: 1rem;
}
/*# sourceMappingURL=src/components/Notice.css.map */
.searchglass {
  width: 1.25rem;
}
.searchglass:hover {
  cursor: pointer;
}
@media screen and (max-width: 767px) {
.searchglass::after {
    content: ' Suche';
}
}
.v--modal h2 {
  color: #0a41c8;
  margin-left: 1rem;
  margin-top: 1rem;
}
#searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
#searchContainer form {
  width: 80%;
  max-width: 400px;
}
#searchContainer input {
  display: block;
  border: 0;
  border-bottom: 1px solid #0a41c8;
  width: 100%;
  margin-bottom: 1rem;
  height: 3rem;
  font-size: 2rem;
}
#searchContainer input::-webkit-input-placeholder {
  color: #0a41c8;
  font-size: 20;
  font-size: 1.5rem;
  padding-left: 1rem;
}
#searchContainer input::-moz-placeholder {
  color: #0a41c8;
  font-size: 20;
  font-size: 1.5rem;
  padding-left: 1rem;
}
#searchContainer input:-ms-input-placeholder {
  color: #0a41c8;
  font-size: 20;
  font-size: 1.5rem;
  padding-left: 1rem;
}
#searchContainer input::-ms-input-placeholder {
  color: #0a41c8;
  font-size: 20;
  font-size: 1.5rem;
  padding-left: 1rem;
}
#searchContainer input::placeholder {
  color: #0a41c8;
  font-size: 20;
  font-size: 1.5rem;
  padding-left: 1rem;
}
#searchContainer input[type='submit'] {
  width: 50%;
  border-bottom: 0;
  font-size: 1rem;
  background-color: #0a41c8;
  color: #fff;
  float: right;
}
#searchContainer input[type='submit']:hover {
  cursor: pointer;
}
@media screen and (min-width: 1920px) {
#searchContainer input[type='submit'] {
    max-width: 121px;
    max-width: 7.56rem;
}
}
#closeButton {
  color: #0a41c8;
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 2em;
}
/*# sourceMappingURL=src/components/SiteSearch.css.map */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}
.hamburger {
    padding: 0;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent !important;
    border: 0;
    margin: 0;
    overflow: visible;
}
.hamburger:hover,
.hamburger:focus {
    opacity: 0.7;
    outline: none;
}
.hamburger-box {
    width: 40px;
    height: 24px;
    display: block;
    position: relative;
    -webkit-transform-origin: top left;
    transform-origin: top left;
}
.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner__before,
.hamburger-inner__after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}
.hamburger-inner__before,
.hamburger-inner__after {
    content: "";
    display: block;
}
.hamburger-inner__before {
    top: -10px;
}
.hamburger-inner__after {
    bottom: -10px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
    -webkit-perspective: 80px;
    perspective: 80px;
}
.hamburger--3dx .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner__before,
.hamburger--3dx .hamburger-inner__after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.hamburger--3dx.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dx.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
    -webkit-perspective: 80px;
    perspective: 80px;
}
.hamburger--3dx-r .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r .hamburger-inner__before,
.hamburger--3dx-r .hamburger-inner__after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}
.hamburger--3dx-r.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dx-r.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
    -webkit-perspective: 80px;
    perspective: 80px;
}
.hamburger--3dy .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy .hamburger-inner__before,
.hamburger--3dy .hamburger-inner__after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
}
.hamburger--3dy.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dy.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
    -webkit-perspective: 80px;
    perspective: 80px;
}
.hamburger--3dy-r .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r .hamburger-inner__before,
.hamburger--3dy-r .hamburger-inner__after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}
.hamburger--3dy-r.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dy-r.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
    -webkit-perspective: 80px;
    perspective: 80px;
}
.hamburger--3dxy .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy .hamburger-inner__before,
.hamburger--3dxy .hamburger-inner__after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateX(180deg) rotateY(180deg);
    transform: rotateX(180deg) rotateY(180deg);
}
.hamburger--3dxy.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dxy.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
    -webkit-perspective: 80px;
    perspective: 80px;
}
.hamburger--3dxy-r .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r .hamburger-inner__before,
.hamburger--3dxy-r .hamburger-inner__after {
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
    transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrow.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrow-r.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner__before {
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt .hamburger-inner__after {
    transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt.is-active .hamburger-inner__before {
    top: 0;
    -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
    transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
    transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
    transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
    transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner__before {
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r .hamburger-inner__after {
    transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r.is-active .hamburger-inner__before {
    top: 0;
    -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
    transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
    transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt-r.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
    transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
    transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.hamburger--arrowturn.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrowturn.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}
.hamburger--arrowturn-r.is-active .hamburger-inner__before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrowturn-r.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner,
.hamburger--boring .hamburger-inner__before,
.hamburger--boring .hamburger-inner__after {
    transition-property: none;
}
.hamburger--boring.is-active .hamburger-inner {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.hamburger--boring.is-active .hamburger-inner__before {
    top: 0;
    opacity: 0;
}
.hamburger--boring.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner__after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner__before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner__after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner__before {
    top: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner__after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner__before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
    transform: translate3d(0, -10px, 0) rotate(45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner__after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner__before {
    top: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner__before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner__after {
    top: 20px;
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner__before {
    transition-delay: 0s;
    opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r .hamburger-inner__before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic-r .hamburger-inner__after {
    top: 20px;
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
    transform: translate3d(0, 10px, 0) rotate(-135deg);
    transition-delay: 0.075s;
}
.hamburger--elastic-r.is-active .hamburger-inner__before {
    transition-delay: 0s;
    opacity: 0;
}
.hamburger--elastic-r.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
    overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner__before {
    left: 0;
    transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.hamburger--emphatic .hamburger-inner__after {
    top: 10px;
    right: 0;
    transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner__before {
    left: -80px;
    top: -80px;
    -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
    transform: translate3d(80px, 80px, 0) rotate(45deg);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner__after {
    right: -80px;
    top: -80px;
    -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
    overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner__before {
    left: 0;
    transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.hamburger--emphatic-r .hamburger-inner__after {
    top: 10px;
    right: 0;
    transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
}
.hamburger--emphatic-r.is-active .hamburger-inner__before {
    left: -80px;
    top: 80px;
    -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
    transform: translate3d(80px, -80px, 0) rotate(-45deg);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner__after {
    right: -80px;
    top: 80px;
    -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
    transform: translate3d(-80px, -80px, 0) rotate(45deg);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner__before,
.hamburger--minus .hamburger-inner__after {
    transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
.hamburger--minus.is-active .hamburger-inner__before,
.hamburger--minus.is-active .hamburger-inner__after {
    opacity: 0;
    transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
.hamburger--minus.is-active .hamburger-inner__before {
    top: 0;
}
.hamburger--minus.is-active .hamburger-inner__after {
    bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
    top: 2px;
}
.hamburger--slider .hamburger-inner__before {
    top: 10px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner__after {
    top: 20px;
}
.hamburger--slider.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner__before {
    -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
    transform: translate3d(0, -20px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
    top: 2px;
}
.hamburger--slider-r .hamburger-inner__before {
    top: 10px;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-timing-function: ease;
    transition-duration: 0.15s;
}
.hamburger--slider-r .hamburger-inner__after {
    top: 20px;
}
.hamburger--slider-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
    transform: translate3d(0, 10px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner__before {
    -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner__after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
    transform: translate3d(0, -20px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner__before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner__after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner__before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner__before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner__after {
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r.is-active .hamburger-inner {
    -webkit-transform: rotate(-225deg);
    transform: rotate(-225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.is-active .hamburger-inner__before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin-r.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
    top: 2px;
    transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner__before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner__after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important;
}
.hamburger--spring.is-active .hamburger-inner__before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
    transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner__after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
    transform: translate3d(0, 10px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r .hamburger-inner__after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
.hamburger--spring-r .hamburger-inner__before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spring-r.is-active .hamburger-inner__after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}
.hamburger--spring-r.is-active .hamburger-inner__before {
    top: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
    transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner__before {
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner__after {
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: transparent !important;
    transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner__before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
    transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner__before {
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner__after {
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r.is-active .hamburger-inner {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    background-color: transparent !important;
    transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner__before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner__before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner__after {
    transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner__before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex .hamburger-inner__before,
.hamburger--vortex .hamburger-inner__after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear;
}
.hamburger--vortex .hamburger-inner__before {
    transition-property: top, opacity;
}
.hamburger--vortex .hamburger-inner__after {
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform;
}
.hamburger--vortex.is-active .hamburger-inner {
    -webkit-transform: rotate(765deg);
    transform: rotate(765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex.is-active .hamburger-inner__before,
.hamburger--vortex.is-active .hamburger-inner__after {
    transition-delay: 0s;
}
.hamburger--vortex.is-active .hamburger-inner__before {
    top: 0;
    opacity: 0;
}
.hamburger--vortex.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r .hamburger-inner__before,
.hamburger--vortex-r .hamburger-inner__after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear;
}
.hamburger--vortex-r .hamburger-inner__before {
    transition-property: top, opacity;
}
.hamburger--vortex-r .hamburger-inner__after {
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform;
}
.hamburger--vortex-r.is-active .hamburger-inner {
    -webkit-transform: rotate(-765deg);
    transform: rotate(-765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r.is-active .hamburger-inner__before,
.hamburger--vortex-r.is-active .hamburger-inner__after {
    transition-delay: 0s;
}
.hamburger--vortex-r.is-active .hamburger-inner__before {
    top: 0;
    opacity: 0;
}
.hamburger--vortex-r.is-active .hamburger-inner__after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
@font-face {
  font-family: 'SuisseIntl';
  src: url("/fonts/SuisseIntl-Regular-WebXL.eot"); /* IE9 Compat Modes */
  src: url("/fonts/SuisseIntl-Regular-WebXL.eot?#iefix") format('embedded-opentype'), url("/fonts/SuisseIntl-Regular-WebXL.woff2") format('woff2'), url("/fonts/SuisseIntl-Regular-WebXL.woff") format('woff'), url("/fonts/SuisseIntl-Regular-WebXL.ttf") format('truetype'), url("/fonts/SuisseIntl-Regular-WebXL.svg#svgFontName") format('svg');
/* Legacy iOS */
}
@font-face {
  font-family: 'FuturaBT-Medium';
  src: url("/fonts/webfonts/39F557_0_0.eot");
  src: url("/fonts/webfonts/39F557_0_0.eot?#iefix") format('embedded-opentype'), url("/fonts/webfonts/39F557_0_0.woff2") format('woff2'), url("/fonts/webfonts/39F557_0_0.woff") format('woff'), url("/fonts/webfonts/39F557_0_0.ttf") format('truetype');
}
body {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
h1,
h2,
h4,
h5,
h6,
h3.accordeonTitle {
  text-transform: uppercase;
  font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  line-height: 1.2;
}
h3 {
  margin: 0;
  line-height: 1.2;
}
h1.logo {
  font-size: 55px;
  font-size: 3.43rem;
}
h2,
h4,
h5,
h6,
h3.titel,
h2.teamtitel,
h3.accordeonTitle {
  font-size: 28px;
  font-size: 1.75rem;
}
h3 {
  font-size: 27px;
  font-size: 1.6875rem;
}
h3.untertitel,
.text h4 {
  font-size: 20px;
  font-size: 1.25rem;
}
.text h4,
.shoutbox h3 {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: none;
  margin-bottom: 0.8rem;
  line-height: 1.4;
}
p.lead,
.cta,
.ctaButton,
.shoutbox h3,
h3 {
  font-size: 20px;
  font-size: 1.25rem;
}
p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
.text h2 {
    font-size: 40px;
    font-size: 2.5rem;
}
}
@media screen and (min-width: 900px) {
h2.maintitle {
    font-size: 44px;
    font-size: 2.75rem;
}
}
@media screen and (min-width: 1400px) {
h2.maintitle {
    font-size: 60px;
    font-size: 3.75rem;
}
}
@media screen and (min-width: 1920px) {
h1.logo,
  h2,
  .text h2,
  .hero h2,
  h2.teamtitel,
  .accordeon h3 {
    font-size: 44px;
    font-size: 2.75rem;
}
h4,
  h5,
  h6 {
    font-size: 30px;
    font-size: 1.875rem;
}
p.lead,
  .cta,
  .ctaButton,
  .shoutbox h3,
  h3 {
    font-size: 30px;
    font-size: 1.875rem;
}
h3.untertitel,
  .text h4 {
    font-size: 30px;
    font-size: 1.875rem;
}
p,
  li {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.4;
}
}
header h2 {
  font-size: 28px;
  font-size: 1.75rem;
}
header h2.sublogo {
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 1920px) {
header h2.sublogo {
    font-size: 18px;
    font-size: 1.125rem;
}
}
header h3 {
  font-size: 16px;
  font-size: 1rem;
  font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
header .langSwitch {
  font-size: 20px;
  font-size: 1.25rem;
}
@media screen and (min-width: 768px) {
header h2 {
    font-size: 18px;
    font-size: 1.125rem;
}
header h3 {
    font-size: 16px;
    font-size: 1rem;
    font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
header .langSwitch {
    font-size: 16px;
    font-size: 1rem;
}
}
@media screen and (min-width: 1920px) {
header h2 {
    font-size: 22px;
    font-size: 1.375rem;
}
header h3 {
    font-size: 18px;
    font-size: 1.125rem;
    font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
header .langSwitch {
    font-size: 18px;
    font-size: 1.125rem;
}
}
.hero h2 {
  font-size: 22px;
  font-size: 1.375rem;
}
@media screen and (min-width: 768px) {
.hero h2 {
    font-size: 40px;
    font-size: 2.5rem;
}
.hero .lead {
    top: 0;
}
.hero .lead h2.bildLegende {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.4;
}
}
@media screen and (min-width: 1920px) {
.hero h2 {
    font-size: 80px;
    font-size: 5rem;
}
.hero .ctas h2 {
    font-size: 28px;
    font-size: 1.75rem;
}
}
.blocklink h4,
h4.untertitel {
  font-size: 16px;
  font-size: 1rem;
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: none;
}
@media screen and (min-width: 768px) {
.blocklink h4,
  h4.untertitel {
    font-size: 22px;
    font-size: 1.25rem;
}
}
@media screen and (min-width: 1920px) {
.blocklink h4,
  h4.untertitel {
    font-size: 20px;
    font-size: 1.25rem;
}
}
.cta h2 {
  font-size: 27px;
  font-size: 1.6875rem;
}
@media screen and (min-width: 1920px) {
.cta h2 {
    font-size: 27px;
    font-size: 1.6875rem;
}
}
footer p {
  font-size: 13px;
  font-size: 0.81rem;
}
@media screen and (min-width: 1920px) {
footer p {
    font-size: 18px;
    font-size: 1.125rem;
}
}
input,
textarea {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
body {
  opacity: 1;
}
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
main {
  flex: 1;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
main {
    margin-top: 4rem;
}
}
@media screen and (min-width: 1920px) {
main {
    margin-top: 5rem;
}
}
#mainBody {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  margin-top: 3rem;
}
#mainBody > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
@media screen and (min-width: 768px) {
#mainBody {
    -ms-grid-columns: 2fr 1rem 1fr;
    grid-template-columns: 2fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-gap: 1rem;
}
#mainBody > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
#mainBody > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
#mainBody .maintitle {
    -ms-grid-row: 1;
    grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    grid-column: 1/2;
    margin-top: -0.35rem;
}
#mainBody .hero {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    -ms-grid-row: 2;
    grid-row: 2;
}
#mainBody #stage {
    -ms-grid-row: 2;
    grid-row: 2;
    margin-top: -0.3rem;
}
#mainBody.teammitglied #stage {
    -ms-grid-row: 1;
    grid-row: 1;
}
#mainBody .boxes {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
}
#mainBody aside {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    -ms-grid-row: 1;
    grid-row: 1;
}
#mainBody.nohero {
    margin-top: 2rem;
}
}
@media screen and (min-width: 1920px) {
#mainBody.nohero {
    margin-top: 4rem;
}
}
@media screen and (min-width: 768px) and (min-width: 1400px) {
#mainBody .maintitle {
    margin-top: -0.5rem;
}
}
.kontakt #mainBody #stage {
  -ms-grid-row: 1;
  grid-row: 1;
}
.kurztext a {
  text-decoration: underline;
  color: #000;
}
.kurztext a:hover {
  color: #000;
}
figure {
  margin-bottom: 2rem;
}
.landingPage ul.switcheridooo {
  margin-left: 1rem;
  margin-top: 2rem;
  position: relative;
  list-style: none;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: center;
}
.landingPage ul.switcheridooo li {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 2rem;
}
.landingPage ul.switcheridooo li::before {
  content: '\2192';
  color: #0a41c8;
  position: absolute;
  left: 0;
  transition: left 0.3s ease-in-out;
}
.landingPage ul.switcheridooo li:hover {
  color: #0a41c8;
}
.landingPage ul.switcheridooo li::before {
  left: 0.25rem;
}
.landingPage ul.switcheridooo li a {
  text-decoration: none;
  color: #0a41c8;
}
.landingPage .switcheridooo {
  opacity: 0;
}
.landingPage main {
  margin-top: 0;
}
.landingPage .videoDone {
  overflow: hidden;
  position: relative;
}
.landingPage .videoDone .outer {
  position: absolute;
  top: 0;
  width: 100%;
}
.landingPage .videoDone #myvid {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.landingPage .videoDone .switcheridooo {
  transition: opacity 0.3s ease;
  transition-delay: 0.3s;
  opacity: 1;
}
nav ul.children li.active a {
  text-decoration: underline;
}
.hero {
  position: relative;
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
.hero {
    margin-bottom: 2rem;
}
}
@media screen and (min-width: 768px) {
.hero {
    margin-bottom: 4rem;
}
}
.hero .bild {
  width: 100%;
}
@media screen and (min-width: 786px) and (max-width: 1920px) {
.hero.heroleft img {
    height: 70vh;
    width: auto;
}
}
.hero .lead {
  position: absolute;
  left: 0;
  top: 1rem;
  width: 100%;
}
.hero .lead h2 {
  color: #fff;
}
.hero .ctas {
  display: none;
}
@media screen and (min-width: 1050px) {
.hero .ctas {
    display: block;
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: right;
}
.hero .ctas.nohero {
    display: none;
}
.hero .ctas li {
    position: absolute;
    right: 0;
}
.hero .ctas li.bigredbutton {
    right: 6.5rem;
}
.hero .ctas li.blocklink {
    top: 17rem;
}
}
@media screen and (min-width: 1400px) {
.hero .ctas {
    top: 40%;
}
}
.maincontent {
  width: 100%;
}
.text {
  padding-left: 0;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
.text {
    margin-bottom: 75px;
    margin-bottom: 1.8rem;
}
}
.text a {
  color: #000;
  text-decoration: underline;
}
.text p {
  margin-bottom: 2rem;
}
.text h3 {
  margin-bottom: 0.8rem;
}
.text ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 1.1rem;
  margin-bottom: 2.6rem;
}
@media screen and (min-width: 768px) {
.text ul {
    width: 75%;
}
}
@media screen and (min-width: 1920px) {
.text ul {
    margin-bottom: 1.6rem;
    width: 50%;
}
}
.text ul li {
  line-height: 1.4;
}
@media screen and (min-width: 1080px) {
.text {
    width: 85%;
}
}
.inner {
  max-width: 1640px;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.hyphens {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.bigredbutton {
  background-color: #f00;
  width: 13rem;
  height: 13rem;
  border-radius: 6.5rem;
  text-align: center;
}
.bigredbutton a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bigredbutton h2 {
  margin-bottom: 0;
  padding-top: 0.4rem;
}
.blocklink {
  width: 245px;
  background-color: #0a41c8;
  color: #fff;
  text-align: right;
  height: 4rem;
  width: 13rem;
}
.blocklink a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.blocklink h2 {
  margin-bottom: 0;
  padding-left: 1rem;
  padding-top: 0.4rem;
}
video.selfhostedVideo {
  width: 100% !important;
  height: auto !important;
}
.VueCarousel .VueCarousel-pagination {
  text-align: left;
  margin: 0 0 1rem 1rem;
}
.VueCarousel button.VueCarousel-dot {
  margin: 0 0.5rem 0 0;
  background: #fff;
  border: #fff solid 2px;
  opacity: 1;
}
.VueCarousel button.VueCarousel-dot.VueCarousel-dot--active {
  background: transparent;
}
.VueCarousel .VueCarousel-slide-adjustableHeight {
  display: block !important;
}
.VueCarousel .VueCarousel-inner {
  align-items: flex-start !important;
}
footer {
  background-color: #0a41c8;
  color: #fff;
  padding-top: 1rem;
}
footer .footerGrid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
footer .footerGrid > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
footer .footerGrid .footerlogo {
  width: 80%;
}
footer .footerGrid li {
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
footer .footerGrid {
    -ms-grid-columns: 4fr 4fr 2fr 1fr 1fr;
    grid-template-columns: 4fr 4fr 2fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
footer .footerGrid > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
footer .footerGrid > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}
footer .footerGrid > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
footer .footerGrid > *:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
}
footer .footerGrid > *:nth-child(5) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
}
footer .footerGrid li {
    -ms-grid-row-align: end;
        align-self: end;
}
footer .footerGrid .oeffnungszeiten,
  footer .footerGrid .links {
    padding-left: 1rem;
}
}
footer .socialIcons li {
  display: inline-block;
  margin-left: 0;
  width: 50px;
  width: 3rem;
}
footer .socialIcons li:not(:last-child) {
  margin-right: 1rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
footer .socialIcons li {
    width: 30px;
    width: 2rem;
}
}
@media screen and (min-width: 768px) {
footer .socialIcons {
    text-align: right;
    margin-left: 1rem;
    margin-right: 0;
}
}
.red header#mainHead {
  background-color: #f00;
  transition: background-color 0.3s ease-in-out;
}
.red header#mainHead.active,
.red header#mainHead.is-open {
  background-color: #db3021;
}
.red .drawer .red {
  display: none;
}
.red .drawer a.cta.white {
  background-color: #fff;
  color: #f00;
}
.red .hero {
  display: none;
}
.red .maincontent,
.red #app,
.red footer {
  background-color: #f00;
  color: #fff;
}
@media screen and (min-width: 768px) {
.red .maincontent {
    margin-top: 2rem;
}
}
@media screen and (min-width: 1920px) {
.red .maincontent {
    margin-top: 8.43rem;
}
}
.blue .hero {
  display: none;
}
.blue .maincontent,
.blue #app,
.blue footer {
  background-color: #0a41c8;
  color: #fff;
}
.blue header#mainHead {
  transition: background-color 0.3s ease-in-out;
}
.blue header#mainHead.active,
.blue header#mainHead.is-open {
  background-color: #183cb2;
}
.blue a {
  color: #fff;
}
#contactform input,
#contactform textarea {
  background-color: #efefef;
  border: none;
  border-radius: 0;
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  color: #000;
  opacity: 1;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: calc(100% - 0.5rem);
  font-size: 18px;
  font-size: 1rem;
}
@media screen and (min-width: 1920px) {
#contactform input,
  #contactform textarea {
    height: 40px;
    height: 2.5rem;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
}
}
#contactform input::-webkit-input-placeholder, #contactform textarea::-webkit-input-placeholder {
  color: #000;
  font-size: 18px;
  font-size: 1rem;
}
#contactform input::-moz-placeholder, #contactform textarea::-moz-placeholder {
  color: #000;
  font-size: 18px;
  font-size: 1rem;
}
#contactform input:-ms-input-placeholder, #contactform textarea:-ms-input-placeholder {
  color: #000;
  font-size: 18px;
  font-size: 1rem;
}
#contactform input::-ms-input-placeholder, #contactform textarea::-ms-input-placeholder {
  color: #000;
  font-size: 18px;
  font-size: 1rem;
}
#contactform input::placeholder,
#contactform textarea::placeholder {
  color: #000;
  font-size: 18px;
  font-size: 1rem;
}
#contactform input[type='submit'],
#contactform textarea[type='submit'] {
  width: 50%;
  background-color: #0a41c8;
  color: #fff;
  float: right;
}
@media screen and (min-width: 1920px) {
#contactform input[type='submit'],
  #contactform textarea[type='submit'] {
    max-width: 121px;
    max-width: 7.56rem;
}
}
#contactform textarea {
  border: none;
}
@media screen and (min-width: 1920px) {
#contactform textarea {
    height: 4.8125rem;
    padding-top: 0.75rem;
}
}
#kontaktinfos {
  margin-bottom: 3rem;
}
@media screen and (min-width: 1920px) {
#kontaktinfos {
    margin-bottom: 8.125rem;
}
}
#kontaktinfos a {
  color: #000;
  text-decoration: underline;
}
#kontaktinfos a:hover {
  color: #000;
}
.Gmap {
  width: 100%;
  position: relative;
}
.Gmap a {
  display: block;
}
.Gmap .btt {
  position: absolute;
  width: 100%;
  height: 2rem;
  bottom: 2rem;
}
.Gmap .btt .inner,
.Gmap .btt a,
.Gmap .btt .longarrow {
  height: 100%;
}
.Gmap .btt a {
  text-align: right;
}
.kontakt main {
  margin-bottom: 0;
}
a.ctaButton,
button.ctaButton {
  color: #fff;
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 2rem;
}
a.ctaButton.blau,
button.ctaButton.blau {
  background-color: #0a41c8;
}
a.ctaButton.rot,
button.ctaButton.rot {
  background-color: #f00;
}
a.ctaButton.standard,
button.ctaButton.standard {
  line-height: 1.4;
  padding: 1rem;
}
a.ctaButton.big,
button.ctaButton.big {
  line-height: 1.4;
  padding: 1rem 2rem;
}
a.ctaButton.gerunded,
button.ctaButton.gerunded {
  border-radius: 3rem;
}
.red a.ctaButton {
  color: #f00;
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline-block;
  margin-bottom: 4rem;
}
.red a.ctaButton.blau {
  background-color: #fff;
}
.red a.ctaButton.rot {
  background-color: #fff;
}
.red a.ctaButton.standard {
  line-height: 1.4;
  padding: 1rem;
}
.red a.ctaButton.big {
  line-height: 1.4;
  padding: 1rem;
}
.red a.ctaButton.gerunded {
  border-radius: 2rem;
}
.backlink {
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  height: 2rem;
  background-color: #0a41c8;
  color: #fff;
  text-align: left;
  margin-bottom: 1rem;
}
article.shoutbox {
  color: #fff;
  background-color: #0a41c8;
  padding: 1rem;
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
article.shoutbox {
    margin-bottom: 0;
}
}
article.shoutbox h2,
article.shoutbox h3 {
  margin-bottom: 1rem;
}
article.shoutbox a {
  text-decoration: underline;
}
article.shoutbox .terminplaner button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #0a41c8;
  background-color: #fff;
}
.teammitglied {
  margin-top: 1rem;
}
.teammitglied a {
  color: #000;
}
.teammitglied a:hover {
  text-decoration: underline;
}
.teammitglied .weiteres p {
  margin-bottom: 1rem;
}
.teammitglied .weiteres ul {
  list-style: disc;
  margin-left: 1.5rem;
  margin-bottom: 2rem;
}
.teammitglied .weiteres ul li {
  margin: 0.5rem 0;
}
.teammitglied .teambeschreibung {
  margin-bottom: 1rem;
}
.teammitglied .teambeschreibung.basics {
  margin-bottom: 2rem;
}
.teammitglied .basics p {
  font-size: 20px;
  font-size: 1.25rem;
}
@media screen and (max-width: 767px) {
.teammitglied #stage {
    order: 2;
}
.teammitglied aside {
    order: 1;
}
}
@media screen and (min-width: 768px) {
.teammitglied {
    margin-top: 4rem;
}
}
@media screen and (min-width: 1920px) {
.teammitglied {
    margin-top: 9rem;
}
.teammitglied h2 {
    margin-bottom: 4.3rem;
}
.teammitglied h3 {
    margin-bottom: 0;
}
.teammitglied .teambeschreibung {
    margin-bottom: 2rem;
}
}
#team h2 {
  margin-bottom: 1rem;
}
#mainHead .ctaCont {
  opacity: 0;
}
#mainHead.active .ctaCont {
  opacity: 1;
}
.text h2 {
  margin-bottom: 1.8rem;
}
@media screen and (min-width: 768px) {
.text h2 {
    margin-bottom: 0.8rem;
}
}
@media screen and (min-width: 1920px) {
.text h2 {
    margin-bottom: 1.8rem;
}
}
p.lead {
  margin-bottom: 2.6rem;
}
@media screen and (min-width: 768px) {
p.lead {
    margin-bottom: 1.8rem;
}
}
.spinner {
  position: relative;
}
.anim {
  display: none;
}
.home .fullscreen .anim {
  display: none;
}
@media screen and (min-width: 768px) {
.fullscreen .anim {
    display: inline;
    width: 5rem;
    opacity: 0;
    position: absolute;
    left: 2rem;
    -webkit-animation: falling 2s ease 4s 3 forwards;
            animation: falling 2s ease 4s 3 forwards;
}
}
@media screen and (min-width: 1921px) {
.anim {
    display: inline;
    width: 10rem;
    opacity: 0;
    position: absolute;
    left: 0;
    -webkit-animation: falling 2s ease 7s 3 forwards;
            animation: falling 2s ease 7s 3 forwards;
}
}
.searchresults #mainBody {
  position: relative;
}
#suchresultate li {
  margin-left: 2.5rem;
  margin-bottom: 0.5rem;
}
#suchresultate li:first-child {
  margin-top: 1rem;
}
#suchresultate li a {
  color: #000;
  font-size: 1.5rem;
}
#suchresultate li a::before {
  content: '\2192';
  color: #000;
  position: absolute;
  left: 0rem;
  transition: all 0.3s ease-in-out;
}
#suchresultate li a:hover {
  color: #0a41c8;
}
#suchresultate li a:hover::before {
  left: 0.75rem;
  color: #0a41c8;
}
.embed {
  margin-bottom: 2rem;
}
.embed iframe {
  max-width: 100%;
}
.responsive {
  position: relative;
  padding-bottom: 56.2%;
  height: 0;
  overflow: hidden;
}
.responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@-webkit-keyframes falling {
0% {
    opacity: 0;
    -webkit-transform: rotate(90deg) translateX(-20vh);
            transform: rotate(90deg) translateX(-20vh);
}
50% {
    opacity: 1;
}
100% {
    opacity: 0;
    -webkit-transform: rotate(90deg) translateX(20vh);
            transform: rotate(90deg) translateX(20vh);
}
}
@keyframes falling {
0% {
    opacity: 0;
    -webkit-transform: rotate(90deg) translateX(-20vh);
            transform: rotate(90deg) translateX(-20vh);
}
50% {
    opacity: 1;
}
100% {
    opacity: 0;
    -webkit-transform: rotate(90deg) translateX(20vh);
            transform: rotate(90deg) translateX(20vh);
}
}
@media all and (-ms-high-contrast: none) {
header .langSwitcherer {
    width: 100%;
}
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
html {
    box-sizing: border-box;
}
*,
  *::before,
  *::after {
    box-sizing: inherit;
}
#mainHead .innerHeader {
    display: flex;
    justify-content: space-between;
}
#mainHead nav {
    width: 100%;
}
#mainHead .logos {
    width: 20%;
}
#mainHead .parents {
    display: flex;
    justify-content: space-between;
}
#mainHead .subnav div {
    display: block;
}
}
@media all and (-ms-high-contrast: none) {
#app {
    display: block;
}
#app .hero img {
    width: 100%;
}
#app main {
    display: block;
    margin: 2rem;
    padding-top: 2rem;
    min-height: calc(100vh - 200px);
}
#app #mainBody {
    display: block;
}
#app #mainBody .maintitle {
    padding-top: 1rem;
}
#app #mainBody.teammitglied {
    display: flex;
}
#app #mainBody.teammitglied #stage {
    width: calc(100% / 3 * 2);
}
#app #mainBody.teammitglied aside {
    width: calc((100% / 3));
}
#app .contentboxes,
  #app .teamboxes {
    overflow: hidden;
    margin-bottom: 2rem;
}
#app .contentboxes ul,
  #app .teamboxes ul {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0 20px;
    margin: 0 -20px -20px 0;
}
#app .contentboxes ul li.contentbox,
  #app .teamboxes ul li.contentbox {
    width: calc((100% / 2));
    height: 400px;
    border: 0 solid transparent;
    border-width: 0 20px 20px 0;
    background-clip: padding-box;
}
#app .contentboxes ul li.contentbox h3.titel,
  #app .teamboxes ul li.contentbox h3.titel {
    font-size: 1rem;
}
#app .contentboxes ul li.contentbox .blocklink h4,
  #app .teamboxes ul li.contentbox .blocklink h4 {
    font-size: 0.8rem;
}
#app .contentboxes ul li.mitglied,
  #app .teamboxes ul li.mitglied {
    width: calc((100% / 2));
    height: 200px;
    border: 0 solid transparent;
    border-width: 0 20px 20px 0;
    background-clip: padding-box;
}
#app .contentboxes ul li.mitglied::before,
  #app .teamboxes ul li.mitglied::before {
    display: none;
}
#app .contentboxes ul li.mitglied:hover a,
  #app .teamboxes ul li.mitglied:hover a {
    color: #000;
}
#app .contentboxes ul li.mitglied h3.titel,
  #app .teamboxes ul li.mitglied h3.titel {
    font-size: 1.75rem;
}
#app .contentboxes ul li.mitglied .blocklink h4,
  #app .teamboxes ul li.mitglied .blocklink h4 {
    font-size: 1.25rem;
}
}
@media all and (-ms-high-contrast: none) and (min-width: 1100px) {
#app .contentboxes ul li.contentbox {
    width: calc((100% / 3));
}
#app .teamboxes ul li.mitglied {
    width: calc((100% / 3));
}
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
#app #mainBody {
    display: -ms-grid;
    display: grid;
    height: 100%;
    -ms-grid-rows: auto 1rem auto;
        grid-template: 'a b' 'c d' 'e';
}
#app .hero {
    grid-area: d;
}
#app .maintitle {
    grid-area: a;
}
#app aside {
    grid-area: b;
}
#app #stage {
    grid-area: c;
}
#app .boxes {
    grid-area: e;
    -ms-grid-column-span: 3;
}
}
@media all and (-ms-high-contrast: none) and (min-width: 768px){
#app .hero {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
}
#app .maintitle {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
#app aside {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
#app #stage {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}
#app .boxes {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
}
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
.accordeon .arrowContainer {
    display: none;
}
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
.ueberUns .contentbox picture {
    display: none;
}
}
/*# sourceMappingURL=src/components/Styles.css.map */
li[data-v-73ba1ce4] {
  margin-bottom: 4rem;
}
/*# sourceMappingURL=src/components/Team.css.map */
.mitglied[data-v-0bf0584e] {
  background-color: #efefef;
  padding: 1rem;
  position: relative;
}
.mitglied[data-v-0bf0584e]::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0a41c8;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.mitglied[data-v-0bf0584e]:hover::before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.mitglied:hover a[data-v-0bf0584e] {
  color: #fff;
}
.mitglied .name[data-v-0bf0584e] {
  z-index: 10;
}
.mitglied a[data-v-0bf0584e] {
  color: #000;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1rem 2rem;
  grid-template-columns: auto 2rem;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 1rem;
  height: 100%;
}
.mitglied a > *[data-v-0bf0584e]:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.mitglied a > *[data-v-0bf0584e]:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
@media screen and (min-width: 550px) {
.mitglied a[data-v-0bf0584e] {
    -ms-grid-columns: auto 1rem 3rem;
    grid-template-columns: auto 3rem;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.mitglied a > *[data-v-0bf0584e]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.mitglied a > *[data-v-0bf0584e]:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
}
.mitglied h3[data-v-0bf0584e] {
  margin-bottom: 0;
}
.mitglied h3.titel[data-v-0bf0584e] {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-break: break-word;
}
.mitglied .ar[data-v-0bf0584e] {
  width: 2rem;
  -ms-grid-row-align: start;
      align-self: start;
}
/*# sourceMappingURL=src/components/TeamBox.css.map */
.teamboxes ul[data-v-456394b0] {
  display: -ms-grid;
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 1rem;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 550px) {
.teamboxes ul[data-v-456394b0] {
    -ms-grid-columns: 1fr 1rem 1fr;
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.teamboxes ul > *[data-v-456394b0]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.teamboxes ul > *[data-v-456394b0]:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
}
@media screen and (min-width: 1100px) {
.teamboxes ul[data-v-456394b0] {
    -ms-grid-columns: 1fr 1rem 1fr 1rem 1fr;
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}
.teamboxes ul > *[data-v-456394b0]:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}
.teamboxes ul > *[data-v-456394b0]:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}
.teamboxes ul > *[data-v-456394b0]:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
}
}
.list-item[data-v-456394b0] {
  transition: all 0.3s;
}
.list-enter[data-v-456394b0],
.list-leave-to[data-v-456394b0] {
  opacity: 0;
}
/*# sourceMappingURL=src/components/TeamBoxes.css.map */
button.ctaButton.gerunded[data-v-6caef06a] {
  border-radius: 3rem;
}
#medicosearchWidget[data-v-6caef06a] {
  height: 70vh;
  overflow: scroll;
}
@media screen and (min-width: 768px) {
#medicosearchWidget[data-v-6caef06a] {
    height: 50vh;
}
}
/*# sourceMappingURL=src/components/TerminPlaner.css.map */
.onoffswitch[data-v-b525c184] {
  position: relative;
  width: 55px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox[data-v-b525c184] {
  display: none;
}
.onoffswitch-label[data-v-b525c184] {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 50px;
}
.onoffswitch-inner[data-v-b525c184] {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner[data-v-b525c184]:before,
.onoffswitch-inner[data-v-b525c184]:after {
  display: block;
  float: left;
  width: 50%;
  height: 18px;
  padding: 0;
  line-height: 18px;
  font-size: 10px;
  color: #fff;
  box-sizing: border-box;
}
.onoffswitch-inner[data-v-b525c184]:before {
  content: 'FR';
  padding-left: 7px;
  background-color: #0a41c8;
  color: #efefef;
}
.onoffswitch-inner[data-v-b525c184]:after {
  content: 'DE';
  padding-right: 7px;
  background-color: #0a41c8;
  color: #efefef;
  text-align: right;
}
.red .onoffswitch-inner[data-v-b525c184]:before,
.red .onoffswitch-inner[data-v-b525c184]:after {
  background-color: #f00;
}
.onoffswitch-switch[data-v-b525c184] {
  display: block;
  width: 18px;
  margin: 0px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 33px;
  border: 2px solid #fff;
  border-radius: 50px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner[data-v-b525c184] {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch[data-v-b525c184] {
  right: 0px;
}
/*# sourceMappingURL=src/components/Toggle.css.map */


/*# sourceMappingURL=app.2dd9c437.css.map*/