


















































































@import '../styles/variables.styl';

nav {
  margin-top: 1.45rem;
  position: relative;
  @media screen and (min-width: 1920px) {
    margin-top: 1rem;

    li:not(:last-child) {
      padding-top: 0.15rem;
    }
  }
}

.parents {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 9rem; // 6rem, if search is on
  grid-template-rows: auto;
  > li {
    cursor: default;
    h2 {
      margin-left: .75rem;
    }
  }

  > li:hover {
    .children {
      max-height: 100vh;
      opacity: 1;
      padding-bottom: .75rem;
      @media screen and (min-width: 1600px) {
        padding-top: .5rem;
      }
      > li {
        display: list-item;
      }
    }
  }
  .mainKnot {
    margin-bottom: 1rem;


    @media screen and (min-width: 1600px) {
      margin-bottom: 0.5rem;
    }
  }
}

.children {
  opacity: 0;
  padding: 0 .75rem;
  max-height: 0;
  transition: max-height .5s ease-in-out;
  background-color: medinBlue ;
  > li {
    display: none;
  }
  h3 {
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.4;
    text-transform: none;
    margin-bottom: 0;
  }
}

.red {
  .children {
    background-color: dampedRed;
  }
  .notfallLink {
    display: none;
  }
}



  .cta {
    position: absolute;
    right: 10%;
    margin-top: 80vh;
    opacity: 1;
    transition: opacity .3s ease-in-out;
  }
  .cta.removed {
    opacity: 0;
    pointer-events: none;
  }


.subnav {
  div {
    display: inline-block;
    margin-top: -0.2rem;

    @media screen and (min-width: 1350px) {
      margin-top: -0.1rem;
    }

    @media screen and (min-width: 1920px) {
      margin-top: 0;
    }

    &.search {
      margin-left: 1rem;
    }
  }
}
