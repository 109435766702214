
































































@import '../styles/variables.styl';

.searchglass {
  width: 1.25rem;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    &::after {
      content: ' Suche';
    }
  }
}

.v--modal {
  h2 {
    color: medinBlue;
    margin-left: 1rem;
    margin-top: 1rem;
  }
}

#searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  form {
    width: 80%;
    max-width: 400px;
  }

  input {
    display: block;
    border: 0;
    border-bottom: 1px solid medinBlue;
    width: 100%;
    margin-bottom: 1rem;
    height: 3rem;
    font-size: 2rem;

    &::placeholder {
      color: medinBlue;
      font-size: 20;
      font-size: 1.5rem;
      padding-left: 1rem;
    }

    &[type='submit'] {
      width: 50%;
      border-bottom: 0;
      font-size: 1rem;

      &:hover {
        cursor: pointer;
      }

      @media screen and (min-width: 1920px) {
        max-width: 121px;
        max-width: 7.56rem;
      }

      background-color: medinBlue;
      color: white;
      float: right;
    }
  }
}

#closeButton {
  color: medinBlue;
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 2em;
}
