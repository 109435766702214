





































@import '../styles/variables.styl';

.langfilter {
  text-align: right;
  margin-bottom: 0.5rem;

  @media screen and (min-width: 550px) {
    text-align: left;
  }

  p {
    color: medinGreenIntense;
    font-size: 100%;
    display: inline;
  }

  button {
    border-radius: 1rem;
    min-width: 3rem;
    min-height: 1.5rem;
    border: none;
    background-color: medinGreenDamped;
    color: medinGreenIntense;

    &.active {
      background-color: medinGreenIntense;
      color: medinGreenDamped;
    }

    &:hover {
      border: 1px solid medinGreenIntense;
    }
  }
}

ul {
  text-align: right;
  display: inline;

  button {
    color: medinGreenDamped;
    transition: color 0.3s ease-in-out;
  }
}

li {
  display: inline;
  margin-left: 0.5rem;

  @media screen and (min-width: 550px) {
    margin-right: 0.5rem;
    margin-left: 0;
  }

  &.active button {
    color: medinGreenIntense;
  }

  button:hover {
    color: medinGreenIntense;
  }
}
