



























.contentboxes {
  ul {
    @media screen and (min-width: 550px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-gap: 1rem;
    }

    @media screen and (min-width: 1100px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      grid-auto-rows: 1fr;
      grid-gap: 1rem;
    }
  }
}
