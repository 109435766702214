
















@import '../styles/variables.styl';

.notice {
  padding: 1rem;
}
