










































nav {
  margin-top: 2rem;
}

h2 {
  margin-bottom: 0;
}

.children {
  margin-bottom: 1rem;
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out all;

  &.is-open {
    max-height: 30rem;
  }

  h3 {
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin-bottom: 0.5rem;
    text-transform: none;
    line-height: 1.2;
  }
}
