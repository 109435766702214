







































@import '../styles/variables.styl';

.accordeon {
  margin-bottom: 1rem;

  .accordeonTitle:hover {
    color: medinBlue;
    cursor: pointer;
  }

  .accordeonTitle {
    display: flex;
    align-items: top;
  }

  .arrowContainer {
    margin-top: 0.5rem;
  }

  .arrow {
    height: 0.8rem;
    margin-bottom: 0.3rem;
    margin-right: 0.3rem;

    &:hover {
      color: medinBlue;
    }
  }

  @media screen and (min-width: 1920px) {
    .arrowContainer {
      margin-top: 0.75rem;
    }

    .arrow {
      height: 1.375rem;
      margin-right: 1rem;
    }
  }

  h3 {
    display: inline;
  }

  div.accordeonContent {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease max-height;
    transition-delay: 0.2s;

    p {
      @media screen and (min-width: 768px) {
        width: 75%;
      }

      @media screen and (min-width: 1920px) {
        margin-bottom: 1.6rem;
        width: 50%;
      }
    }

    ul {
      list-style-type: disc;
      list-style-position: outside;
      padding-left: 1.6rem;

      li {
        line-height: 1.4;
        padding-left: 0.2rem;
      }

      @media screen and (min-width: 768px) {
        width: 75%;
      }

      @media screen and (min-width: 1920px) {
        width: 50%;
        padding-left: 2.375rem;

        li {
          padding-left: 1.2rem;
        }
      }
    }
  }

  &.isOpen {
    div.accordeonContent {
      max-height: 50rem;
    }
  }

  a {
    color: medinBlack;
    text-decoration: underline;

    &:hover {
      color: medinBlack;
      text-decoration: underline;
    }
  }
}
