






























































.teamboxes {
  ul {
    display: grid;
    // grid-template-rows: auto;
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
    grid-template-columns: 1fr;

    @media screen and (min-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }

    @media screen and (min-width: 1100px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
    }
  }
}

// vue trans
.list-item {
  transition: all 0.3s;
}

.list-enter, .list-leave-to {
  opacity: 0;
}
