









@import '../styles/variables.styl';

button {
  background-color: medinBlue;
  border-radius: 1rem;
  padding-left: 1rem;
}
