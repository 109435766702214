











@import '../styles/variables.styl';

ul {
  text-align: right;
}

li {
  display: inline;
  margin-right: 1rem;
  box-sizing: border-box;

  &.active a {
    background-color: medinGreenIntense;
    color: medinBlue;
  }

  // &:hover a {
  // border: 1px solid medinGreenIntense;
  // }
  a {
    text-transform: uppercase;
    font-size: 100%;
    display: inline;
    border-radius: 1rem;
    min-width: 3rem;
    min-height: 1.5rem;
    border: none;
    background-color: medinGreenDamped;
    color: medinBlue;
    padding: 0.095rem 0.83rem;
  }
}
