



































































@import '../styles/variables.styl';

#mainHead {
  padding: 1rem;
  min-height: 4rem;
  background-color: medinblue;
  color: white;
}

a {
  text-decoration: none;
  color: white;

  &:hover {
    color: white;
  }
}

.innerHead {
  margin: 1rem;
}

.sublogo {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: none;
}

.hamberder {
  float: right;
  margin-top: 1rem;

  &:hover, &:focus {
    opacity: 1;
  }
}

// when Search active
// .subnav {
// display: grid;
// grid-template-columns: auto 1fr;

// @media screen and (max-width: 767px) {
// align-items: baseline;
// }
// }
.drawer {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s ease all;
}

.is-open .drawer {
  margin-top: 2rem;
  max-height: 50rem;
  overflow: visible;
}
