







































#LogoMedin {
  height: 2.5rem;
}
