// target ie10 - ie 11 above mobile

// --------------------------------------
// Hinweis
// -------------------------------------
@media all and (-ms-high-contrast: none) {
    header {
        .langSwitcherer {
            width: 100%;
        }
    }
}

@media all and (-ms-high-contrast: none) and (min-width: 768px) {
    html {
        box-sizing: border-box;
    }

    *, *::before, *::after {
        box-sizing: inherit;
    }

    // --------------------------------------
    // Fixes Nav Stuff
    // -------------------------------------
    #mainHead {
        .innerHeader {
            display: flex;
            justify-content: space-between;
        }

        nav {
            width: 100%;
        }

        .logos {
            width: 20%;
        }

        .parents {
            display: flex;
            justify-content: space-between;
        }

        .subnav div {
            display: block;
        }
    }
}

// --------------------------------------
// Fixes Main Stuff
// -------------------------------------
@media all and (-ms-high-contrast: none) {
    #app {
        display: block;

        .hero {
            img {
                width: 100%;
            }
        }

        main {
            display: block;
            margin: 2rem;
            padding-top: 2rem;
            min-height: calc(100vh - 200px);
        }

        #mainBody {
            display: block;

            .maintitle {
                padding-top: 1rem;
            }
        }

        #mainBody.teammitglied {
            display: flex;

            #stage {
                width: calc(100% / 3 * 2);
            }

            aside {
                width: calc((100% / 3));
            }
        }

        .contentboxes, .teamboxes {
            overflow: hidden;
            margin-bottom: 2rem;

            ul {
                display: flex;
                flex-wrap: wrap;
                padding: 20px 0 0 20px;
                margin: 0 -20px -20px 0;

                li.contentbox {
                    width: calc((100% / 2));
                    height: 400px;
                    border: 0 solid transparent;
                    border-width: 0 20px 20px 0;
                    background-clip: padding-box;

                    h3.titel {
                        font-size: 1rem;
                    }

                    .blocklink h4 {
                        font-size: 0.8rem;
                    }
                }

                li.mitglied {
                    width: calc((100% / 2));

                    &::before {
                        display: none;
                    }

                    &:hover a {
                        color: medinBlack;
                    }

                    height: 200px;
                    border: 0 solid transparent;
                    border-width: 0 20px 20px 0;
                    background-clip: padding-box;

                    h3.titel {
                        font-size: 1.75rem;
                    }

                    .blocklink h4 {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
}

@media all and (-ms-high-contrast: none) and (min-width: 1100px) {
    #app {
        .contentboxes {
            ul {
                li.contentbox {
                    width: calc((100% / 3));
                }
            }
        }

        .teamboxes {
            ul {
                li.mitglied {
                    width: calc((100% / 3));
                }
            }
        }
    }
}

// --------------------------------------
// ANGEBOT Darstellungsfix
// -------------------------------------
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
    #app {
        #mainBody {
            display: grid;
            height: 100%;
            grid-template: 'a b' 'c d' 'e';
        }

        .hero {
            grid-area: d;
        }

        .maintitle {
            grid-area: a;
        }

        aside {
            grid-area: b;
        }

        #stage {
            grid-area: c;
        }

        .boxes {
            grid-area: e;
            -ms-grid-column-span: 3;
        }
    }
}

// --------------------------------------
// AKKORDEON Fix
// -------------------------------------
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
    .accordeon {
        .arrowContainer {
            display: none;
        }
    }
}

// --------------------------------------
// NEWS FIX
// -------------------------------------
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
    .ueberUns {
        .contentbox {
            picture {
                display: none;
            }
        }
    }
}
