
// --------------------------------------
// Fonts
// -------------------------------------
@font-face {
    font-family: 'SuisseIntl';
    src: url('~/fonts/SuisseIntl-Regular-WebXL.eot'); /* IE9 Compat Modes */
    src: url('~/fonts/SuisseIntl-Regular-WebXL.eot?#iefix') format('embedded-opentype'), url('~/fonts/SuisseIntl-Regular-WebXL.woff2') format('woff2'), url('~/fonts/SuisseIntl-Regular-WebXL.woff') format('woff'), url('~/fonts/SuisseIntl-Regular-WebXL.ttf') format('truetype'), url('~/fonts/SuisseIntl-Regular-WebXL.svg#svgFontName') format('svg');
    /* Legacy iOS */
}

@import url('//hello.myfonts.net/count/39f557');

@font-face {
    font-family: 'FuturaBT-Medium';
    src: url('~/fonts/webfonts/39F557_0_0.eot');
    src: url('~/fonts/webfonts/39F557_0_0.eot?#iefix') format('embedded-opentype'), url('~/fonts/webfonts/39F557_0_0.woff2') format('woff2'), url('~/fonts/webfonts/39F557_0_0.woff') format('woff'), url('~/fonts/webfonts/39F557_0_0.ttf') format('truetype');
}

// --------------------------------------
// GENERAL Font Settings
// -------------------------------------
body {
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
    hyphens: auto;
}

h1, h2, h4, h5, h6, h3.accordeonTitle {
    text-transform: uppercase;
    font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0;
    line-height: 1.2;
}

h3 {
    margin: 0;
    line-height: 1.2;
}

h1.logo {
    font-size: 55px;
    font-size: 3.43rem;
}

h2, h4, h5, h6, h3.titel, h2.teamtitel, h3.accordeonTitle {
    font-size: 28px;
    font-size: 1.75rem;
}

h3 {
    font-size: 27px;
    font-size: 1.6875rem;
}

h3.untertitel, .text h4 {
    font-size: 20px;
    font-size: 1.25rem;
}

.text h4, .shoutbox h3 {
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-transform: none;
    margin-bottom: 0.8rem;
    line-height: 1.4;
}

p.lead, .cta, .ctaButton, .shoutbox h3, h3 {
    font-size: 20px;
    font-size: 1.25rem;
}

p {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.4;
}

// IN BETWEEN
@media screen and (min-width: 768px) {
    .text h2 {
        font-size: 40px;
        font-size: 2.5rem;
    }
}

// Big Title Specials
@media screen and (min-width: 900px) {
    h2.maintitle {
        font-size: 44px;
        font-size: 2.75rem;
    }
}

@media screen and (min-width: 1400px) {
    h2.maintitle {
        font-size: 60px;
        font-size: 3.75rem;
    }
}

// FULLHD
@media screen and (min-width: 1920px) {
    h1.logo, h2, .text h2, .hero h2, h2.teamtitel, .accordeon h3 {
        font-size: 44px;
        font-size: 2.75rem;
    }

    // h3 {
    // font-size: 34px;
    // font-size: 2.125rem;
    // }
    h4, h5, h6 {
        font-size: 30px;
        font-size: 1.875rem;
    }

    p.lead, .cta, .ctaButton, .shoutbox h3, h3 {
        font-size: 30px;
        font-size: 1.875rem;
    }

    h3.untertitel, .text h4 {
        font-size: 30px;
        font-size: 1.875rem;
    }

    p, li {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 1.4;
    }
}

// --------------------------------------
// HEADER
// -------------------------------------
header {
    h2 {
        font-size: 28px;
        font-size: 1.75rem;

        &.sublogo {
            font-size: 16px;
            font-size: 1rem;

            @media screen and (min-width: 1920px) {
                font-size: 18px;
                font-size: 1.125rem;
            }
        }
    }

    h3 {
        font-size: 16px;
        font-size: 1rem;
        font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .langSwitch {
        font-size: 20px;
        font-size: 1.25rem;
    }
}

// IN BETWEEN
@media screen and (min-width: 768px) {
    header {
        h2 {
            font-size: 18px;
            font-size: 1.125rem;
        }

        h3 {
            font-size: 16px;
            font-size: 1rem;
            font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }

        .langSwitch {
            font-size: 16px;
            font-size: 1rem;
        }
    }
}

// FULLHD
@media screen and (min-width: 1920px) {
    header {
        h2 {
            font-size: 22px;
            font-size: 1.375rem;
        }

        h3 {
            font-size: 18px;
            font-size: 1.125rem;
            font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }

        .langSwitch {
            font-size: 18px;
            font-size: 1.125rem;
        }
    }
}

// --------------------------------------
// HERO
// -------------------------------------
.hero {
    h2 {
        font-size: 22px;
        font-size: 1.375rem;
    }
}

// IN BETWEEN
@media screen and (min-width: 768px) {
    .hero h2 {
        font-size: 40px;
        font-size: 2.5rem;
    }

    .hero .lead {
        top: 0;

        h2.bildLegende {
            font-size: 20px;
            font-size: 1.25rem;
            line-height: 1.4;
        }
    }
}

// FULLHD
@media screen and (min-width: 1920px) {
    .hero {
        h2 {
            font-size: 80px;
            font-size: 5rem;
        }

        .ctas {
            h2 {
                font-size: 28px;
                font-size: 1.75rem;
            }
        }
    }
}

// --------------------------------------
// Buttons
// -------------------------------------
.blocklink h4, h4.untertitel {
    font-size: 16px;
    font-size: 1rem;
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-transform: none;
}

// IN BETWEEN
@media screen and (min-width: 768px) {
    .blocklink h4, h4.untertitel {
        font-size: 22px;
        font-size: 1.25rem;
    }
}

// FULLHD
@media screen and (min-width: 1920px) {
    .blocklink h4, h4.untertitel {
        font-size: 20px;
        font-size: 1.25rem;
    }
}

// --------------------------------------
// CTAs
// -------------------------------------
.cta {
    h2 {
        font-size: 27px;
        font-size: 1.6875rem;
    }
}

// IN BETWEEN

// FULLHD
@media screen and (min-width: 1920px) {
    .cta {
        h2 {
            font-size: 27px;
            font-size: 1.6875rem;
        }
    }
}

// --------------------------------------
// FOOTER
// -------------------------------------
footer p {
    font-size: 13px;
    font-size: 0.81rem;
}

@media screen and (min-width: 1920px) {
    footer p {
        font-size: 18px;
        font-size: 1.125rem;
    }
}

// --------------------------------------
// FORMS
// -------------------------------------
input, textarea {
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}